import setupDate from "./time.js";
import {setupTable} from "./table.js";
import "./tabs.js"

const loading = document.querySelector(".loading-page")
addEventListener("load", ()=>{
    loading.classList.add("loading-page--hide")
})

setupDate()
setupTable()
