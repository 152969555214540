import {formatDistanceToNow} from 'date-fns'
import faIR from 'date-fns/esm/locale/fa-IR'

const time = document.querySelector(".time__text")

const date = new Date(2023, 4, 25, 20, 30, 1)

export default function setupDate() {
    let distanceText = formatDistanceToNow(date,
        {
            locale: faIR,
            addSuffix: true
        })

    distanceText = distanceText.replace("قبل", "پیش")
    time.textContent = distanceText
}


