import  configs from'../configs/configs.json'

const templateRow = document.querySelector("[data-template-row]")
const tbody = document.querySelector("tbody")


export function setupTable() {
    renderCreateTable()
}

function renderCreateTable() {
    configs.forEach((con, index) => {
        const row = templateRow.content.cloneNode(true)

        const configNumber = row.querySelector(".configs__number")
        configNumber.innerText = index + 1

        const configName = row.querySelector(".configs__name")
        configName.innerText = con.configName

        const configType = row.querySelector(".configs__type")
        configType.innerText = con.configType


        const configs__copy = row.querySelector(".configs__copy")
        configs__copy.dataset.config = con.configLink


        tbody.appendChild(row)
    })

    setupEventListeners()

}

function setupEventListeners() {
    // copy icon
    const btnCopies = tbody.querySelectorAll(".configs__copy-btn")
    btnCopies.forEach(cp => {
        const cpIcon = cp.querySelector(".configs__copy-icon")
        cp.addEventListener("click", () => {
            if (navigator == null || navigator.clipboard == null) {
                alert("کپی نشد\nمرورگرتون رو بروزسانی کنید")
                return
            }
            navigator.clipboard.writeText(cp.parentElement.dataset.config)
                .then(() => {
                    hideCopyIcon(cp, cpIcon)
                    setTimeout(() => showCopyIcon(cp, cpIcon), 2000)
                })
                .catch(()=>alert("کپی انجام نشد!"))
        })
    })

}


function hideCopyIcon(cp, i) {
    i.classList.remove("icon-copy")
    i.classList.add("icon-check")
    cp.classList.add("configs__copy-btn--copied")
}

function showCopyIcon(cp, i) {
    i.classList.remove("icon-check")
    i.classList.add("icon-copy")
    cp.classList.remove("configs__copy-btn--copied")
}

