[
  {
    "configName": "Direct1",
    "configLink": "vmess://eyJhZGQiOiIxODUuMTYyLjIzNS4yNTAiLCJhaWQiOiIwIiwiaG9zdCI6IjE4NS4xNjIuMjM1LjI1MCIsImlkIjoiNmYxMTdhZjQtY2Q1Mi00MGY4LTkyMzYtN2M1ZTNmMzU3Y2M4IiwibmV0Ijoid3MiLCJwYXRoIjoiL3NvY2tldC5pbyIsInBvcnQiOiIyMDU0IiwicHMiOiJOTF8xMiIsInNjeSI6ImF1dG8iLCJzbmkiOiIiLCJ0bHMiOiIiLCJ0eXBlIjoiYXV0byIsInYiOiIyIn0=\n",
    "configType": "vmess"
  },
  {
    "configName": "Direct2",
    "configLink": "vmess://eyJhZGQiOiIxNTQuNDAuNDAuMTAiLCJhaWQiOiIwIiwiaG9zdCI6IiIsImlkIjoiM2Q2ZjI0YTEtOThkOS00MzNmLThjNTctNWRmNmJhNmQ2N2E2IiwibmV0IjoicXVpYyIsInBhdGgiOiIvIiwicG9ydCI6IjUzNDMxIiwicHMiOiJuamZzZHZzZHYiLCJzY3kiOiJhdXRvIiwic25pIjoiIiwidGxzIjoiIiwidHlwZSI6ImR0bHMiLCJ2IjoiMiJ9\n",
    "configType": "vmess"
  },
  {
    "configName": "Direct3",
    "configLink": "trojan://e0d44ae7-cb7d-4acc-a8c0-9861a6f5eaad@51.91.11.29:80?security=tls&type=tcp#jhbjmhnb%20\n",
    "configType": "trojan"
  },
  {
    "configName": "Direct4",
    "configLink": "vmess://eyJhZGQiOiI5Ni40My44Ni40IiwiYWlkIjoiMCIsImhvc3QiOiIiLCJpZCI6ImU2N2QwMTA0LTlkZTMtNGE1YS05MTQxLWJlNjczZmJmMDk0ZSIsIm5ldCI6InRjcCIsInBhdGgiOiIvIiwicG9ydCI6IjE3MTI5IiwicHMiOiJTYW50YSBDbGFyaXRhIiwic2N5IjoiYXV0byIsInNuaSI6IiIsInRscyI6IiIsInR5cGUiOiJodHRwIiwidiI6IjIifQ==\n",
    "configType": "vmess"
  },
  {
    "configName": "Direct5",
    "configLink": "vmess://eyJhZGQiOiI5Ni40My44Ni40IiwiYWlkIjoiMCIsImhvc3QiOiIiLCJpZCI6IjZiOGJhY2I1LTc1YjItNGExNy05ZDMyLTNkNzViMTAwZTYxZSIsIm5ldCI6InRjcCIsInBhdGgiOiIvIiwicG9ydCI6IjQ0MyIsInBzIjoiU2FudGEgQ2xhcml0YS0xNTk2Iiwic2N5IjoiYXV0byIsInNuaSI6IiIsInRscyI6IiIsInR5cGUiOiJodHRwIiwidiI6IjIifQ==\n",
    "configType": "vmess"
  },
  {
    "configName": "Direct6",
    "configLink": "vmess://eyJhZGQiOiIxMDguMTg2LjExNi4xNzIiLCJhaWQiOiI2NCIsImhvc3QiOiIiLCJpZCI6IjQxODA0OGFmLWEyOTMtNGI5OS05YjBjLTk4Y2EzNTgwZGQyNCIsIm5ldCI6InRjcCIsInBhdGgiOiIvIiwicG9ydCI6IjU1MDA1IiwicHMiOiJTYW4gSm9zZS0zMTE4Iiwic2N5IjoiYXV0byIsInNuaSI6IiIsInRscyI6IiIsInR5cGUiOiJub25lIiwidiI6IjIifQ==\n",
    "configType": "vmess"
  }
]